<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:headline>店舗アカウント編集</template>
              <template v-slot:body>
                <ShopForm ref="editForm" :isEdit="true" />
              </template>
              <template v-if="isApprovedShop" v-slot:footer>
                <button class="btn btn-black" type="button" @click="showModal">QRコードを表示</button>
                <ModalQR
                  :code="shopDetail.code"
                  :name="shopDetail.name"
                  :handleCloseModal="closeModal"
                  :isModalQrShown="modal"
                />
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item">
            <button class="btn btn-red" type="button" @click="showModal('deleteModal')">削除</button>
          </li>
          <li class="listGrid-item">
            <button class="btn btn-yellow" type="button" @click="showModal('pauseModal')">{{ paused ? '再開' : '一時停止' }}</button>
          </li>
          <li class="listGrid-item" v-if="isJudgeButtonShown">
            <button class="btn btn-green" type="button" @click="showModal('judgeModal')">審査する</button>
          </li>
          <li class="listGrid-item pos-end">
            <ul class="listGrid">
              <li class="listGrid-item">
                <router-link class="btn btn-white" :to="{ name: 'EventShop'}">キャンセル</router-link>
              </li>
              <li class="listGrid-item">
                <ActionButton className="btn btn-main" buttonText="編集する" :handleSubmit="edit" />
              </li>
            </ul>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
    <Modal @close="closeModal('deleteModal')" v-if="deleteModal">
      <template v-slot:headline>削除</template>
      <template v-slot:body>
        <p class="description text-align-center">対象の店舗情報を削除しますか？</p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button class="btn btn-lg btn-white" type="button" @click="closeModal('deleteModal')">キャンセル</button>
          </li>
          <li class="listGrid-item">
            <ActionButton
              class="btn btn-lg btn-red"
              :handleSubmit="handleDelete"
              buttonText="削除する"
            />
          </li>
        </ul>
      </template>
    </Modal>
    <Modal @close="closeModal('pauseModal')" v-if="pauseModal">
      <template v-slot:headline>{{ paused ? '再開' : '一時停止' }}</template>
      <template  v-slot:body>
        <p v-if="paused" class="description text-align-center">
          一時停止を解除し、この加盟店での支払いが行えるようにしますか？<br>この加盟店の店舗設定やクーポン、お知らせなどの公開も再開されます。
        </p>
        <p v-if="!paused" class="description text-align-center">
          一時停止し、この加盟店では支払いが行えないようにしますか？<br>この加盟店の店舗設定やクーポン、お知らせなども非公開となります。
        </p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button class="btn btn-lg btn-white" type="button" @click="closeModal('pauseModal')">キャンセル</button>
          </li>
          <li class="listGrid-item">
            <ActionButton
              class="btn btn-lg btn-yellow"
              :handleSubmit="handlePause"
              :buttonText="paused ? '再開する' : '一時停止にする'"
            />
          </li>
        </ul>
      </template>
    </Modal>
    <Modal @close="closeModal('judgeModal')" v-if="judgeModal">
      <template v-slot:headline>審査</template>
      <template v-slot:body>
        <FormRow>
          <template v-slot:content>
            <div class="form-content-row">
              <ul class="selectBtn">
                <li v-for="item in Object.values(judgeTypes)" :key="item.value" class="selectBtn-item">
                  <label class="selectBtn-btn" :class="item.class">
                    <input
                      class="selectBtn-input"
                      type="radio"
                      :value="item.value"
                      name="judgeType"
                      v-model="statusForm.status"
                    />
                    <span class="selectBtn-label">{{ item.label }}</span>
                  </label>
                </li>
              </ul>
            </div>
          </template>
        </FormRow>
        <transition name="fade">
          <FormRow v-if="statusForm.status === judgeTypes.hold.value">
            <template v-slot:label>保留理由</template>
            <template v-slot:labelNote>（100文字以内）</template>
            <template v-slot:content>
              <div class="form-content-row">
                <textarea
                  v-trim
                  v-maxlength
                  maxlength="100"
                  class="form-control form-textarea"
                  name="note"
                  v-model="statusForm.note"
                ></textarea>
              </div>
            </template>
          </FormRow>
        </transition>
      </template>
      <template v-slot:footer>
        <ul class="listGrid justify-content-end">
          <li class="listGrid-item">
            <button class="btn btn-white" type="button" @click="closeModal('judgeModal')">キャンセル</button>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-main" :handleSubmit="handleExamination" buttonText="登録する" />
          </li>
        </ul>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';
//mixin
import nav from '@/mixins/nav/shop';
import modal from '@/mixins/plugin/modal';
import edit from '@/mixins/plugin/edit';
import error from '@/mixins/plugin/error';
//component
import Modal from '@/components/Modal.vue';
import ModalQR from '@/components/ModalQR.vue';
import ShopForm from './Form.vue';
import FormRow from '@/components/FormRow.vue';
import ActionButton from '@/components/ActionButton.vue';
//constant
import { StatusConstants } from '@/constants/status';

export default {
  name: 'EventShopEdit',
  data: function() {
    return {
      pageName: '店舗管理',
      pauseModal: false,
      judgeModal: false,
      deleteModal: false,
      judgeTypes: StatusConstants.judge,
      statusForm: {
        note: '',
        status: '',
        shops: [],
        allFlag: false,
      },
      storeModule: 'shop',
    };
  },
  mixins: [nav, modal, edit, error],
  components: {
    ShopForm,
    Modal,
    FormRow,
    ActionButton,
    ModalQR
  },
  validations: {
    statusForm: {
      status: { required },
      note: { maxLength: maxLength(100) },
    }
  },
  computed: {
    ...mapGetters({
      shopDetail: 'shop/shopDetail',
    }),
    isJudgeButtonShown() {
      return [StatusConstants.shop.waiting.value, StatusConstants.shop.hold.value].includes(this.shopDetail.status);
    },
    isApprovedShop() {
      return this.shopDetail.status === StatusConstants.shop.approved.value;
    },
    paused() {
      return !this.shopDetail.validFlag;
    }
  },
  watch: {
    shopDetail() {
      if (this.isJudgeButtonShown) {
        this.updateStatusForm();
      }
    },
    judgeModal(val) {
      if (val) {
        this.updateStatusForm();
      }
    }
  },
  methods: {
    updateStatusForm() {
      this.statusForm = {
        status: this.shopDetail.status,
        shops: [this.shopDetail.id],
        note: this.shopDetail.note,
        allFlag: false,
      };
    },
    async edit() {
      const formData = this.$refs.editForm.validate();
      if (formData) {
        const result = await this.$store.dispatch('shop/updateShop', formData);
        if (result) {
          await this.$router.push({ name: 'EventShop' });
          this.$message.updated('shopInfor');
        }
      }
    },
    async handleExamination() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.statusForm, 'judge');
      } else {
        const result = await this.$store.dispatch('shop/updateShopExamination', this.statusForm);
        this.closeModal('judgeModal');
        if (result) {
          this.$store.dispatch('shop/getShopDetail', { id: this.shopDetail.id });
          if (this.statusForm.status === StatusConstants.shop.hold.value) {
            this.$message.showSuccess('updatedWaitingStatus');
          } else {
            this.$message.showSuccess('updatedApprovedOrRejectedStatus');
          }
        }
      }
    },
    async handlePause() {
      const result = await this.$store.dispatch('shop/updateShopStatus', {
        shopId: this.shopDetail.id,
        status: this.paused ? 1 : 0,
      });
      this.closeModal('pauseModal');
      if (result) {
        this.$store.dispatch('shop/getShopDetail', { id: this.shopDetail.id });
        this.$message.updated('status');
      }
    },
    async handleDelete() {
      const result = await this.$store.dispatch('shop/deleteShop', this.shopDetail.id);
      if (result) {
        await this.$router.push({ name: 'EventShop' });
        this.$message.deleted('shop');
      }
    }
  },
};
</script>
